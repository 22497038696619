<template>
  <div class="video-background">
    <div class="video-foreground">
      <!-- set volume -->
      <iframe
        ref="youtube"
        :src="`https://www.youtube.com/embed/${vidid}?version=3&mute=${muted}&controls=0&showinfo=0&rel=0&autoplay=1&loop=1&modestbranding=1&autohide=1&playlist=${vidid}&start=60&vq=hd1080&fs=1&hd=1`"
        frameborder="0"
        allow="autoplay"
        allowfullscreen="1"
      ></iframe>
      <!-- <YouTube class="player" :src="vidUrl" ref="youtube" :vars="playerVars" /> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    vidid: String,
    mute: Boolean,
  },
  computed: {
    muted(){
      return this.mute ? "1" : "0";
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}
.video-background {
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
} 
</style>