<template>
  <DraggableDiv @drag-end="newPos" :style="showPos">
    <template v-slot:header>
      <div class="d-flex bgspex justify-content-between">
        <h3 class="p-2"><i class="fas fa-edit"></i> Editor</h3>
        <div>
          <!-- <button class="btn btn-outline-dark rounded-0 m-1">Transparent?</button> -->
          <button
            @click="showEditor = !showEditor"
            class="btn btn-outline-dark rounded-0 m-1"
          >
            <i class="far fa-window-minimize" v-if="showEditor"></i>
            <i class="far fa-window-maximize" v-else></i>
          </button>
        </div>
      </div>
    </template>
    <template v-if="showEditor" v-slot:main>
      <!-- {{content}} -->
      <select  class="form-select form-select-sm" v-model="theme">
        <option  value="wb">✨Editor Theme: Default White</option>
        <option value="tb">✨Editor Theme: See-Through</option>
      </select>
      <h3><span class="badge bg-dark mt-2">Editor Size</span></h3>
      <!-- <input type="range" class="form-range" min="0" max="500" v-model="size" style="width: 25rem;" /> -->
      <input type="range" class="form-range" min="0" max="500" v-model="size"/>
      <div class="bgspex" :style="editorStyle">
        <QuillEditor
          :style="editorSize"
          v-model:content="content"
          theme="snow"
          toolbar="essential"
          ref="editor"
        />
      </div>
      <div class="btn-group">
        <button class="btn btn-dark mt-3" @click="saveHTML">
          <i class="fa fa-html5 me-2"></i> Download as HTML
        </button>
        <button class="btn btn-dark mt-3 ms-3" @click="savePDF">
          <i class="fa fa-file-pdf me-2"></i>Download as PDF
        </button>
        <button class="btn btn-dark mt-3 ms-3" @click="saveDOC">
          <i class="fa fa-file-word me-2"></i>Download as Word Doc
        </button>
      </div>
    </template>
  </DraggableDiv>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import DraggableDiv from "./DragCard";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import state from "../store/index.js";
import html2pdf from "html2pdf.js";

export default {
  components: {
    DraggableDiv,
    QuillEditor,
  },
  setup() {
    let editor = ref(null);
    let showPos = computed(() => {
      return state.pos.docedit;
    });
    let size = ref("0");
    // let editorSize = ref({
    //   height: "30rem",
    //   width: "50rem",
    // });
    let editorSize = computed(() => {
      let s = parseInt(size.value);
      return {
        height: `${s * 2.5/100 + 30}rem`,
        width: `${s * 5/100 + 50}rem`,
      };
    });
    let content = ref({});
    let newPos = (e) => {
      state.methods.setDocEditPosition(e);
      // console.log(state.pos.spotifycard);
    };
    let theme = ref("wb");
    let editorStyle = computed(() => {
      return theme.value === "tb" ? "" : "background-color: white";
    });

    // let save = () => {

    //   console.log(editor.value.getHTML());
    // };

    let saveHTML = () => {
      var a = document.body.appendChild(document.createElement("a"));
      a.download = "export.html";
      a.href = "data:text/html," + editor.value.getHTML();
      console.log(a);
      a.click();
    };

    let savePDF = () => {
      var opt = {
        margin: 1,
        filename: "myfile.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf(editor.value.getHTML(), opt);
    };

    let saveDOC = () => {
      let title = "VibeRoom"
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>" +
        title +
        "</title></head><body>";
      var postHtml = "</body></html>";
      var html = preHtml + editor.value.getHTML() + postHtml;
      var blob = new Blob(["\\ufeff", html], {
        type: "application/msword",
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      let filename = "VibeRoom Document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    };

    let showEditor = ref(true);


    return {
      theme,
      editorStyle,
      editor,
      showEditor,
      showPos,
      newPos,
      content,
      editorSize,
      size,
      saveHTML,
      savePDF,
      saveDOC,

    };
  },
};
</script>

<style>
</style>