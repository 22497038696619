<template>
    <DraggableDiv @drag-end="newPos" :style="showPos">
      <template v-slot:header>
        <h3 class="p-2 bgspex"><i class="fab fa-spotify"></i> Room Playlist</h3>
      </template>
      <template v-slot:main>
        <!-- "https://open.spotify.com/embed/playlist/0V5IsHm0VJbmeffuLzgoc3?utm_source=generator" -->
        <div class="card bgspex" style="">
          <iframe
            :src="surl"
            width="100%"
            height="380"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media *; fullscreen; picture-in-picture"
          ></iframe>
          <div class="card-body">
            <div class="row row-cols-lg-auto g-3 align-items-center">
              <div class="col-12">
                <div class="form-floating bgspex">
                  <input
                    type="text"
                    class="form-control"
                    id="roomID"
                    v-model="temp"
                    placeholder="Spotify URL"
                    required
                  />
                  <label for="roomID">Spotify URL</label>
                </div>
              </div>
              <div class="col-12">
                <button
                  @click="changePlaylist"
                  class="btn btn-outline-dark shadow-none"
                >
                  Enter <i class="fas fa-sync-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DraggableDiv>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import DraggableDiv from "./DragCard";
import state from "../store/index.js";

export default {
  components: {
    DraggableDiv,
  },
  props: {
    pid: {
      type: String,
    }
  },

  setup(props, context) {
    let temp = ref("");
    // alert(state.pos.spotifycard);
    let showPos = computed(() => {
      return state.pos.spotifycard;
    });
    let newPos = (e) => {
      state.methods.setSpotifyCardPosition(e);
      // console.log(state.pos.spotifycard);
    };
    let surl = computed(() => {
      return (
        "https://open.spotify.com/embed/" + props.pid + "?utm_source=generator"
      );
    });
    let changePlaylist = () => {
      let regex = /^(https:\/\/open.spotify.com\/)([a-zA-Z0-9/]+)(.*)$/gm;
      context.emit("change-playlist", {
        id: regex.exec(temp.value)[2],
      });
      temp.value = "";
    };
    return {
      surl,
      temp,
      showPos,
      newPos,
      changePlaylist
    };
  },
};
</script>

<style>
</style>