<template>
  <vibe-room />
</template>

<script>
import VibeRoom from "../components/VibeRoom.vue";
// @ is an alias to /src
// temp! { "vidid": "R4B36TMMGp4", "pid": "playlist/0V5IsHm0VJbmeffuLzgoc3", "roomName": "My Vibe Room!!", "links": "{¨links¨|¦¨title¨¨url¨‡¨Google¨¨https://www.google.com¨¨Youtube¨¨https://www.youtube.com¨¨Facebook¨¨https://www.facebook.com¨¨Twitter¨¨https://www.twitter.com¨¨Instagram¨¨https://www.instagram.com¨¨Gumroad¨¨https://gumroad.com/¨—÷}" }

export default {
  name: "Home",
  components: {
    VibeRoom,
  },
  // props: {
  //   VidId: {
  //     type: String,
  //     default: "R4B36TMMGp4",
  //   },
  //   Pid: {
  //     type: String,
  //     default: "playlist/0V5IsHm0VJbmeffuLzgoc3",
  //   },
  //   RoomName: {
  //     type: String,
  //     default: "My Vibe Room!!",
  //   },
  //   Links: {
  //     type: String,
  //     default:
  //       "{¨links¨|¦¨title¨¨url¨‡¨Google¨¨https://www.google.com¨¨Youtube¨¨https://www.youtube.com¨¨Facebook¨¨https://www.facebook.com¨¨Twitter¨¨https://www.twitter.com¨¨Instagram¨¨https://www.instagram.com¨¨Gumroad¨¨https://gumroad.com/¨—÷}",
  //   },
  // },
};
</script>
