<template>
  <!-- style="left: 2rem; top: 10rem" -->
  <DraggableDiv @drag-end="newPos" :style="showPos">
    <template v-slot:header>
      <h3 class="p-2 bgspex">Shareable Link</h3>
    </template>
    <template v-slot:main>
      <div class="card bgspex" style="width: 18rem">
        <div class="card-body">
          <!-- <div class="form-floating bgspex mb-3">
            <input
              type="text"
              class="form-control"
              id="roomID"
              v-model="vid"
              placeholder="YouTube ID for your Room"
              required
            />
            <label for="roomID">Youtube ID</label>
          </div>

          <button @click="newRoom" class="btn btn-outline-dark shadow-none">
            Enter <i class="fas fa-sync-alt"></i>
          </button> -->
          <!-- /share/:VidId/:Pid/:RoomName/:Links -->
          <textarea
            @focus="$event.target.select()"
            class="bglink"
            v-model="url"
            style="height: 5rem"
          />
          <router-link
            class="btn btn-outline-dark"
            target="_blank"
            :to="{
              name: 'Share',
              params: {
                VidId: params.vidid,
                RoomName: params.roomName,
                Pid: params.pid,
                Links: params.links,
              },
            }"
            >Open this link! <i class="fas fa-external-link-alt"></i
          ></router-link>
        </div>
      </div>
    </template>
  </DraggableDiv>
</template>

<script>
import { computed } from "vue";
import state from "../store/index.js";
import { useRouter } from "vue-router";

import DraggableDiv from "./DragCard";
export default {
  props: ["params"],
  setup(props) {
    let showPos = computed(() => {
      return state.pos.sharelink;
    });
    let router = useRouter();
    let url = computed(() => {
      return (
        "https://viberooms.serenebase.com" +
        router.resolve({
          name: "Share",
          params: {
            VidId: props.params.vidid,
            RoomName: props.params.roomName,
            Pid: props.params.pid,
            Links: props.params.links,
          },
        }).href
      );
    });
    let newPos = (e) => {
      state.methods.setShareLinkPosition(e);
    };
    return {
      showPos,
      newPos,
      url,
    };
  },
  components: {
    DraggableDiv,
  },
};
</script>
// set border color to gray
<style>
.bglink {
  background-color: rgba(241, 251, 255, 0);
  width: 100%;
  border: 1px solid #ccc;
}

</style>

