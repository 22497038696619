import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'

// let item = localStorage.getItem("spotifycard");

// if (item) {
//     store.pos.spotifycard = item;
// }

// item = localStorage.getItem("ambiencecard");

// if (item) {
//     store.pos.ambiencecard = item;
// }

// item = localStorage.getItem("docedit");

// if (item) {
//     store.pos.docedit = item;
// }

// item = localStorage.getItem("roomname");

// if (item) {
//     store.pos.roomname = item;
// }

createApp(App).use(router).mount('#app')


