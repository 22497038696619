<template>
  <div class="stylesets" :style="cursorStyle">
    <main-menu-card @new-room="changeAmbience" @mute-change="muteChange" />
    <vid-bg :vidid="vidid" :mute="mute" />
    <spotify-card :pid="pid" @change-playlist="changePlaylist" />
    <doc-edit />
    <room-name @change-room-name="changeRoomName" :roomName="roomName" />
    <link-board @add-link="addLink" @remove-link="removeLink" :links="links" />
    <share-link :params="params" />
    <change-cursor @change="change_cursor" />
    <ad-card />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import MainMenuCard from "./MainMenuCard";
import SpotifyCard from "./SpotifyCard.vue";
import VidBg from "./VidBg";
import DocEdit from "./DocEdit.vue";
import RoomName from "./RoomName.vue";
import LinkBoard from "./LinkBoard.vue";
import ShareLink from "./ShareLink.vue";
import ChangeCursor from "./ChangeCursor.vue";
import { stringify, parse } from "zipson";
import { computed } from "@vue/runtime-core";
import AdCard from './AdCard.vue';
import { onBeforeMount } from "vue";

// import { computed } from "@vue/runtime-core";

// temp! { "vidid": "R4B36TMMGp4", "pid": "playlist/0V5IsHm0VJbmeffuLzgoc3", "roomName": "My Vibe Room!!", "links": "{¨links¨|¦¨title¨¨url¨‡¨Google¨¨https://www.google.com¨¨Youtube¨¨https://www.youtube.com¨¨Facebook¨¨https://www.facebook.com¨¨Twitter¨¨https://www.twitter.com¨¨Instagram¨¨https://www.instagram.com¨¨Gumroad¨¨https://gumroad.com/¨—÷}" }

export default {
  components: {
    VidBg,
    MainMenuCard,
    SpotifyCard,
    DocEdit,
    RoomName,
    LinkBoard,
    ShareLink,
    ChangeCursor,
    AdCard,
  },
  // props: ["VidId", "Pid", "RoomName", "Links"],
  //   props: ["VidId"],
  props: {
    VidId: {
      type: String,
      default: "LFOx-vmYrts",
    },
    Pid: {
      type: String,
      default: "playlist/0V5IsHm0VJbmeffuLzgoc3",
    },
    RoomName: {
      type: String,
      default: "My Vibe Room!!",
    },
    Links: {
      type: String,
      default:
        "{¨links¨|¦¨title¨¨url¨‡¨Serenebase¨¨https://www.serenebase.com/¨¨My Twitter¨¨https://twitter.com/ri_maiti¨¨Indie Hackers¨¨https://www.indiehackers.com/¨¨Twitter @Serenebase ¨¨https://twitter.com/serenebase¨¨Browse Viberooms¨¨https://www.serenebase.com/viberooms/browse¨—÷}",
    },
  },
  setup(props) {
    // state.methods.init_pos();
    // state.pos.init = true;
    onBeforeMount(() => {
      localStorage.removeItem("https://www.youtube.com/");
      //localStorage.removeItem("https://open.spotify.com/");
    });
    let vidid = ref(props.VidId);
    let pid = ref(props.Pid);
    let roomName = ref(props.RoomName);
    let links = ref(parse(props.Links).links);
    let mute = ref(true);
    let cursorStyle = ref("cursor: auto");
    let change_cursor = (e) => {
      // console.log(123,e.cursor);
      if (e.cursor == "default") {
        cursorStyle.value = "cursor: auto";
      }
      else if (typeof e.cursor == 'undefined') {
        cursorStyle.value = "cursor: auto";
      } else {
        cursorStyle.value = `cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>` + e.cursor + `</text></svg>") 16 0,auto;`
      }
    };
    let params = computed(() => {
      return {
        vidid: vidid.value,
        pid: pid.value,
        roomName: roomName.value,
        links: stringify({
          links: links.value,
        }),
      };
    });

    let changeAmbience = (e) => {
      vidid.value = e.id;
    };
    let changePlaylist = (e) => {
      pid.value = e.id;
    };
    let changeRoomName = (e) => {
      roomName.value = e;
    };
    let addLink = (e) => {
      links.value.push(e);
      console.log(stringify({ links: links.value }));
    };
    let removeLink = (e) => {
      links.value.splice(links.value.indexOf(e), 1);
    };
    let muteChange = (e) => {
      mute.value = e.mute;
    };

    //catch keypresses
    document.addEventListener("keydown", function (e) {
      // prevent fullscreen
      if (e.key == "F11") {
        e.preventDefault();
      }
      
    });

    return {
      vidid,
      pid,
      changeAmbience,
      changePlaylist,
      roomName,
      changeRoomName,
      links,
      addLink,
      removeLink,
      params,
      mute,
      muteChange,
      cursorStyle,
      change_cursor,

    };
  },
};
</script>

<style>
.stylesets {
  font-family: "Montserrat", sans-serif;
}
</style>