<template>
  <DraggableDiv @drag-end="newPos" :style="showPos">
    <template v-slot:header>
      <!-- <h3 v-if="!edit" class="p-2 bgspex">{{ roomName }}</h3>
      <button class="btn btn-outline-dark bgspex shadow-none">Edit</button> -->
      <div class="d-flex justify-content-between">
        <h3 class="p-2 bgspex">{{ roomName }}</h3>
        <div>
          <button @click="edit = !edit" class="btn btn-dark rounded-2 m-1 ms-4">
            <i class="far fa-edit"></i>
          </button>
        </div>
      </div>
    </template>
    <template v-slot:main>

      <div v-if="edit" class="card bgspex" style="">
        <div class="card-body">
          <div class="row row-cols-lg-auto align-items-center">
            <div class="col-12">
              <div class="form-floating mb-1 bgspex">
                <input type="text" class="form-control" id="roomID" v-model="name" placeholder="New VibeRoom Name"
                  required />
                <label for="roomID">New VibeRoom Name</label>
              </div>
            </div>
            <div class="col-12">
              <button @click="changeName" class="btn btn-outline-dark shadow-none">
                Save <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="card bgspex mt-2">
        <div class="card-body">
          <a class="btn btn-dark m-1" href="https://www.serenebase.com/viberooms" target="_blank">
            🏠 Viberooms Home
          </a>
          <a class="btn btn-dark m-1" href="https://www.serenebase.com/viberooms/browse" target="_blank">
            🔍 Browse Viberooms
          </a>
        </div>
      </div> -->
      <a class="btn btn-dark m-1" href="https://www.serenebase.com/viberooms" target="_blank">
        🏠 Viberooms Home
      </a>
      <br>
      <a class="btn btn-dark m-1" href="https://www.serenebase.com/viberooms/browse" target="_blank">
        🔍 Browse Viberooms
      </a>
    </template>
  </DraggableDiv>
</template>

<script>
import { ref } from "@vue/reactivity";
import state from "../store/index.js";
import { computed } from "@vue/runtime-core";
import DraggableDiv from "./DragCard";

export default {
  props: {
    roomName: {
      type: String,
    },
  },
  components: {
    DraggableDiv,
  },
  setup(props, context) {
    let name = ref(props.roomName);
    let edit = ref(false);
    let showPos = computed(() => {
      return state.pos.roomname;
    });
    let newPos = (e) => {
      state.methods.setRoomNamePosition(e);
      // console.log(state.pos.spotifycard);
    };
    let changeName = () => {
      context.emit("change-room-name", name.value);
      edit.value = false;
    };
    return {
      name,
      edit,
      showPos,
      newPos,
      changeName
    };
  },
};
</script>

<style>

</style>