<template>
  <router-view/>
</template>

<style>
.form-control:focus {
  border-color: rgba(0, 0, 0, 0.973);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(255, 255, 255, 0.712);
}

.btn:focus {
  border-color: rgba(0, 0, 0, 0.973);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(255, 255, 255, 0.712);
}
.bgspex {
  background-color: rgba(156, 156, 156, 0.712);
  width: 100%;
}

.bgspex-2 {
  background-color: rgba(251,250,253,0.712);
  width: 100%;
}

/* ::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: rgb(0, 0, 0);
}


::-webkit-scrollbar-thumb {
  background-color: #00bbff6e;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #00bbff8a;
} */

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  background-color: #242627;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #878b8d;
}

</style>
