<template>
  <div ref="draggableContainer" id="draggable-container">
    <div
      ref="dragHeader"
      id="draggable-header"
      style="cursor: grab"
      @mousedown="dragMouseDown"
    >
      <slot name="header"></slot>
    </div>
    <slot name="main"></slot>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: "DraggableDiv",
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    };
  },
  methods: {
    dragMouseDown: function (event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      this.$refs.dragHeader.style.cursor = "grabbing";
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      let newY =
        this.$refs.draggableContainer.offsetTop - this.positions.movementY;
      if (newY >= 0) {
        this.$refs.draggableContainer.style.top = newY + "px";
      } else {
        this.$refs.draggableContainer.style.top = 0;
      }

      if (
        this.$refs.draggableContainer.offsetLeft - this.positions.movementX >=
        0
      ) {
        this.$refs.draggableContainer.style.left =
          this.$refs.draggableContainer.offsetLeft -
          this.positions.movementX +
          "px";
      } else {
        this.$refs.draggableContainer.style.left = 0;
      }
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      this.$refs.dragHeader.style.cursor = "grab";
      this.$emit("drag-end", {
        left: this.$refs.draggableContainer.style.left,
        top: this.$refs.draggableContainer.style.top,
      });
    },
  },
  
};
</script>

<style>
#draggable-container {
  position: absolute;
  z-index: 9;
}

#draggable-header {
  z-index: 10;
}
</style>