<template>
    <DraggableDiv @drag-end="newPos" :style="showPos">
        <template v-slot:header>
            <h3 class="p-2 bgspex">Cursor Style</h3>
        </template>
        <template v-slot:main>
            <!-- <button class="btn btn-dark mt-3" @click="test">Test</button> -->
            <select  class="form-select form-select-sm" v-model="selected">
                <option value="default">Default Cursor</option>
                <option value="😃">😃</option>
                <option value="🚀">🚀</option>
                <option value="❤️">❤️</option>
                <option value="🤖">🤖</option>
                <option value="🐱">🐱</option>
                <option value="🐶">🐶</option>
                <option value="🐰">🐰</option>
            </select>
            <button class="btn btn-dark mt-2" @click="changeCursor">
                Change Cursor
            </button>
        </template>
    </DraggableDiv>
</template>

<script>
import DraggableDiv from "./DragCard";
import state from "../store/index.js";
import { computed, ref } from '@vue/runtime-core';

export default {
    setup(_, context) {
        let showPos = computed(() => {
            return state.pos.changecursor;
        });
        let newPos = (e) => {
            state.methods.setChangeCursorPosition(e);
        };
        let selected = ref("default");
        let changeCursor = () => {
            // console.log(selected);
            context.emit("change", {
                cursor: selected.value
            });
        };
        return {
            showPos,
            newPos,
            selected,
            changeCursor
        };
    },
    components: {
        DraggableDiv,
    },
};
</script>

<style>
/* html {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>😀</text></svg>") 16 0,auto;
} */
</style>