<template>
  <DraggableDiv @drag-end="newPos" :style="showPos">
    <template v-slot:header>
      <h3 class="p-2 bgspex">Link Board <i class="fas fa-link"></i></h3>
    </template>
    <template v-slot:main>
      <div class="card bgspex" style="">
        <div class="card-body">
          <div class="list-group">
            <li
              class="list-group-item d-flex justify-content-between bgspex"
              v-for="link in links"
              :key="link.url"
            >
              <a
                class="btn btn-outline-dark px-3 me-2"
                :href="link.url"
                target="_blank"
              >
                <img
                  :src="
                    'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' +
                    link.url +
                    '&size=32'
                  "
                  width="24"
                />
                {{ link.title }}
              </a>
              <button class="btn btn-outline-dark" @click="removeLink(link)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </li>
            <!-- <a
              type="button"
              class="list-group-item list-group-item-action"
              aria-current="true"
              v-for="link in links"
              :key="link.url"
              :href="link.url"
              target="_blank"
            >
              <img
                :src="
                  'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' +
                  link.url +
                  '&size=32'
                "
                width="24"
              />
              {{ link.title }}
            </a> -->
          </div>
          <button v-if="!edit" class="btn btn-dark mt-3" @click="edit = true">
            <i class="fas fa-plus me-3"></i>Add a new link
            <!-- <div v-if="!edit">
              <i class="fas fa-plus me-3"></i>Add a new link
            </div>
            <div v-else><i class="far fa-times-circle me-3"></i>Cancel</div> -->
          </button>
          <div v-if="edit">
            <div class="mt-3 form-floating bgspex">
              <input
                type="text"
                class="form-control"
                id="linkurl"
                v-model="linkUrl"
                placeholder="Link URL"
                required
              />
              <label for="linkurl">Link URL</label>
            </div>
            <div class="mt-1 form-floating bgspex">
              <input
                type="text"
                class="form-control"
                id="linktext"
                v-model="linkName"
                placeholder="Display Text"
                required
              />
              <label for="linktext">Display Text</label>
            </div>
            <div class="btn-group">
              <button
                class="btn btn-outline-dark mt-3 shadow-none"
                @click="addLink"
              >
                <i class="fas fa-plus me-1"></i>Add Link
              </button>
              <button
                class="btn btn-outline-dark mt-3 ms-3 shadow-none"
                @click="edit = false"
              >
                <i class="far fa-times-circle me-1"></i>Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </DraggableDiv>
</template>

<script>
// import { ref } from "@vue/reactivity";
import state from "../store/index.js";
import { computed, ref } from "@vue/runtime-core";
import DraggableDiv from "./DragCard";

export default {
  props: ["links"],
  components: {
    DraggableDiv,
  },
  setup(_, context) {
    let edit = ref(false);
    let linkName = ref("");
    let linkUrl = ref("");
    let addLink = () => {
      context.emit("add-link", {
        title: linkName.value,
        url: linkUrl.value,
      });
      linkName.value = "";
      linkUrl.value = "";
      edit.value = false;
    };
    let showPos = computed(() => {
      return state.pos.linkboard;
    });
    let newPos = (e) => {
      state.methods.setLinkBoardPosition(e);
    };
    let removeLink = (link) => {
      context.emit("remove-link", link);
    };
    return {
      showPos,
      newPos,
      linkName,
      linkUrl,
      edit,
      addLink,
      removeLink,
    };
  },
};
</script>

<style>
</style>