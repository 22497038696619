<template>
    <DraggableDiv @drag-end="newPos" :style="showPos">
        <template v-slot:header>
            <h3 class="p-2 bgspex-2" style="width: 27rem">Serenebase</h3>
            
           

        </template>
        <template v-slot:main>
            <span class="badge bg-dark m-2">Promoted</span>
            <div class="card bgspex-2" style="width: 27rem">
                <div class="card-body">
                    <h5 class="card-title">Serenebase: Curated Mental Health Resources <img :src="
                    'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=' +
                    'https://www.serenebase.com/' +
                    '&size=32'" width="24" /></h5>
                    <p class="card-text">On Serenebase, you
                        can find a variety of mental health apps and resources, all in one place. Whether you're looking
                        for a way to manage your stress, or you need some help coping with anxiety, Serenebase has you
                        covered.</p>
                    <a href="https://www.serenebase.com/" class="btn btn-outline-dark stretched-link" target="_blank">Learn More</a>
                </div>
            </div>
            <!-- <button class="btn btn-dark mt-3 btn-sm">Advertise with us!</button> -->
            <a class="btn btn-dark mt-3 btn-sm" href="https://www.serenebase.com/viberooms/ads" target="_blank">Advertise with us!</a>


        </template>
    </DraggableDiv>
</template>

<script>
import DraggableDiv from "./DragCard";
import state from "../store/index.js";
import { computed } from '@vue/runtime-core';
export default {
    setup() {
        let showPos = computed(() => {
            return state.pos.adcard;
        });
        let newPos = (e) => {
            state.methods.setAdCardPosition(e);
        };
        return {
            showPos,
            newPos
        };
    },
    components: {
        DraggableDiv
    }
};
</script>

<style>

</style>