<template>
  <!-- style="left: 2rem; top: 10rem" -->
  <DraggableDiv @drag-end="newPos" :style="showPos">
    <template v-slot:header>
      <h3 class="p-2 bgspex">Room Ambience 🌄</h3>
    </template>
    <template v-slot:main>
      <div class="card bgspex" style="width: 18rem">
        <div class="card-body">
          <div class="form-floating bgspex mb-3">
            <input
              type="text"
              class="form-control"
              id="roomID"
              v-model="vurl"
              placeholder="YouTube URL for your Room"
              required
            />
            <label for="roomID">Youtube URL</label>
          </div>

          <button @click="newRoom" class="btn btn-outline-dark shadow-none">
            Enter <i class="fas fa-sync-alt"></i>
          </button>
          <!-- mute or not check box and mute emoji-->
          <div class="mt-4 form-check form-switch">
            <!-- label in bold -->
            
            <input
              type="checkbox"
              id="mute1"
              class="form-check-input"
              v-model="mute"
              @click="muteChange"
            />
            <!-- <label class="form-check-label" for="mute1"> Mute🔇 </label> -->
            <label class="form-check-label" for="mute1" v-if="mute">Muted 🔇</label>
            <label class="form-check-label" for="mute1" v-else>Ambience Audio 🔊</label>
          </div>

          <!-- <label for="customRange1" class="form-label">Example range</label>
          <input type="range" class="form-range" id="customRange1" /> -->
        </div>
      </div>
    </template>
  </DraggableDiv>
</template>

<script>
import { ref, computed } from "vue";
import state from "../store/index.js";

import DraggableDiv from "./DragCard";
export default {
  setup(_, context) {
    const vurl = ref("");
    const mute = ref(true);
    let showPos = computed(() => {
      return state.pos.ambiencecard;
    });
    let newPos = (e) => {
      state.methods.setAmbienceCardPosition(e);
      // console.log(state.pos.ambiencecard);
    };
    let newRoom = () => {
      if (vurl.value) {
        // get the youtube id from the url
        let vid = vurl.value.split("v=")[1];
        let ampersandPosition = vid.indexOf("&");
        if (ampersandPosition != -1) {
          vid = vid.substring(0, ampersandPosition);
        }
        context.emit("new-room", {
          id: vid,
        });
      }
    };
    let muteChange = () => {
      mute.value = !mute.value;
      context.emit("mute-change", {
        mute: mute.value,
      });
    };
    return {
      vurl,
      newRoom,
      showPos,
      newPos,
      mute,
      muteChange,
    };
  },
  components: {
    DraggableDiv,
  },
};
</script>

<style>
</style>

