import { reactive } from "vue";

let sync_store = (key,val) => {
    //sync pos with local storage
    localStorage.setItem(key,val);
}

// spotifycard	left: 1515px; top: 400px;	
// sharelink	left: 1204px; top: 468px;	
// linkboard	left: 21px; top: 295px;	
// changecursor	left: 731px; top: 28px;	
// docedit	left: 393px; top: 185px;	
// roomname	left: 348px; top: 19px;	
// adcard	left: 1225px; top: 15px;	
// ambiencecard	left: 28px; top: 13px;

// let pos = reactive({
//     spotifycard: localStorage.getItem("spotifycard")||'left: 24rem; top: 12rem',
//     ambiencecard: localStorage.getItem("ambiencecard")||'left: 2rem; top: 12rem',
//     docedit: localStorage.getItem("docedit")||'left: 48rem; top: 12rem',
//     roomname: localStorage.getItem("roomname")||'left: 39rem; top: 2rem',
//     linkboard: localStorage.getItem("linkboard")||'left: 84rem; top: 12rem',
//     sharelink: localStorage.getItem("sharelink")||'left: 84rem; top: 2rem',
//     changecursor: localStorage.getItem("changecursor")||'left: 84rem; top: 15rem',
//     adcard: localStorage.getItem("adcard")||'left: 84rem; top: 18rem',
// });

// convert px to rem
let px2rem = (px) => {
    return px/16;
}

let pos = reactive({
    spotifycard: localStorage.getItem("spotifycard")||'left: '+px2rem(1515)+'rem; top: '+px2rem(400)+'rem',
    ambiencecard: localStorage.getItem("ambiencecard")||'left: '+px2rem(28)+'rem; top: '+px2rem(13)+'rem',
    docedit: localStorage.getItem("docedit")||'left: '+px2rem(393)+'rem; top: '+px2rem(185)+'rem',
    roomname: localStorage.getItem("roomname")||'left: '+px2rem(348)+'rem; top: '+px2rem(19)+'rem',
    linkboard: localStorage.getItem("linkboard")||'left: '+px2rem(21)+'rem; top: '+px2rem(295)+'rem',
    sharelink: localStorage.getItem("sharelink")||'left: '+px2rem(1204)+'rem; top: '+px2rem(468)+'rem',
    changecursor: localStorage.getItem("changecursor")||'left: '+px2rem(731)+'rem; top: '+px2rem(28)+'rem',
    adcard: localStorage.getItem("adcard")||'left: '+px2rem(1225)+'rem; top: '+px2rem(15)+'rem',
});




const methods = {
    setSpotifyCardPosition(e) {
        pos.spotifycard = "left: " + e.left + "; top: " + e.top + ";";
        sync_store("spotifycard",pos.spotifycard);
    },
    setAmbienceCardPosition(e) {
        pos.ambiencecard = "left: " + e.left + "; top: " + e.top + ";";
        sync_store("ambiencecard",pos.ambiencecard);
    },
    setDocEditPosition(e) {
        pos.docedit = "left: " + e.left + "; top: " + e.top + ";";
        sync_store("docedit",pos.docedit);
    },
    setRoomNamePosition(e) {
        pos.roomname = "left: " + e.left + "; top: " + e.top + ";";
        sync_store("roomname",pos.roomname);
    },
    setLinkBoardPosition(e) {
        pos.linkboard = "left: " + e.left + "; top: " + e.top + ";";
        sync_store("linkboard",pos.linkboard);
    },
    setShareLinkPosition(e) {
        pos.sharelink = "left: " + e.left + "; top: " + e.top + ";";
        sync_store("sharelink",pos.sharelink);
    },
    setChangeCursorPosition(e) {
        pos.changecursor = "left: " + e.left + "; top: " + e.top + ";";
        sync_store("changecursor",pos.changecursor);
    },
    setAdCardPosition(e) {
        pos.adcard = "left: " + e.left + "; top: " + e.top + ";";
        sync_store("adcard",pos.adcard);
    },
}

export default {
    pos,
    methods
}

